<template>
<div>
   <ZeroDepot :souscription="souscription" :user="user"/>
   <div class="zone-content-form wf-section">
    <div class="container-content-form-suite w-container">
      <div class="w-layout-grid grid-form">
        <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-a531d367" class="div-precedent">
          <a href="javascript:void(0)" @click="$parent.previous()" class="zone-precedent w-inline-block"></a>
        </div>
        <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-a531d367" class="div-content-form">
          <p class="p_txt_field_form_v2">Souscription à l&#x27;assurance Zéro Dépôt<span class="text-span-9"></span></p>
          <p class="p_txt_field_form_v2 violet _20px-margin-top">Veuillez indiquer le type de paiement souhaité :</p>
          <p class="p_txt_field_form_v3">Retrouvez ci-dessous les modalités de paiement pour votre assurance Zéro Dépôt.</p>
          <div class="div-type-paiement">
            <div class="div-premier-paiement">
              <div class="div-titre-premier-paiement">
                <p class="titre-premier-paiement">Votre 1<sup>er</sup> paiement <span class="text-span-46">immédiat</span> à la souscription : <span class="text-span-52">{{ $store.api.prix(cotisation + souscription.frais_adhesion) }}</span></p>
              </div>
              <div class="div-detail-paiement">
                <p class="description-prix-v2 titre">Détail de votre 1<sup>er</sup> paiement :</p>
                <p class="description-prix-v2">- Vos 3 premiers mois de cotisations, soit : <span class="text-span-53">{{ $store.api.prix(cotisation) }}</span> </p>
                <p class="description-prix-v2">- Les frais d&#x27;adhésion : <span class="text-span-53">{{ $store.api.prix(souscription.frais_adhesion) }}</span></p>
              </div>
              <div class="div-paiement-mensuel-v2"><img :src="require('@/assets/images/ic_check.svg')" loading="lazy" alt="">
                <p class="paiement-v2">Paiement mensuel de <span class="text-span-55">{{$store.api.prix(souscription.prix_mensuel)}}/mois</span></p>
              </div>
              <div class="div-titre-seconde-paiement">
                <p class="titre-premier-paiement arpes"><span class="text-span-47">Après</span> votre 1er paiement,</p>
              </div>
              <div class="div-titre-seconde-paiement _2nd-ligne">
                <p class="titre-premier-paiement">la cotisation mensuelle sera de : <span class="text-span-54">{{$store.api.prix(souscription.prix_mensuel)}}/mois</span></p>
              </div>
              <div class="div-detail-paiement">
                <div class="div-block-39">
                  <p class="description-prix-v2 detail2">Votre second paiement sera prélevé 3 mois après la date d&#x27;effet de votre bail, soit le {{ next_pay }}.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="div-zone-paiements">
            <div class="div-paiement-mensuel select" v-if="souscription.paiement.periode === 'mensuel'">
              <div>
                <p class="paiement select">Paiement mensuel</p>
                <p class="prix select">{{$store.api.prix(souscription.prix_mensuel)}}/mois</p>
              </div>
              <div>
                <p class="_1ere-ligne-prix-violet select mensuel">1er paiement</p>
                <p class="_2eme-ligne-prix-violet select">de {{ $store.api.prix(souscription.prix_mensuel + souscription.frais_adhesion) }}</p>
              </div>
              <p class="description-prix select">Ce paiement inclus votre première mensualité ainsi que 40€ de frais d&#x27;adhésion.</p>
              <a href="javascript:void(0)" class="bouton-select-prix selectionne mensuel w-button"></a>
            </div>
            <div class="div-paiement-mensuel" v-else>
              <div>
                <p class="paiement">Paiement mensuel</p>
                <p class="prix">{{$store.api.prix(souscription.prix_mensuel)}}/mois</p>
              </div>
              <div>
                <p class="_1ere-ligne-prix-violet mensuel">1er paiement</p>
                <p class="_2eme-ligne-prix-violet">de {{ $store.api.prix(souscription.prix_mensuel + souscription.frais_adhesion) }}</p>
              </div>
              <p class="description-prix">Ce paiement inclus votre première mensualité ainsi que 40€ de frais d&#x27;adhésion.</p>
              <a href="javascript:void(0)" @click="periode('mensuel')" class="bouton-select-prix mensuel w-button">sélectionner</a>
            </div>
            <div class="div-paiement-annuel select" v-if="souscription.paiement.periode === 'annuel'">
              <div>
                <p class="paiement select">Paiement annuel</p>
                <p class="prix select">{{$store.api.prix(souscription.prix_annuel)}}/an</p>
                <p class="prix-sous-titre select">au lieu de  {{ $store.api.prix(souscription.prix_mensuel * 12) }}/an</p>
              </div>
              <div>
                <p class="_1ere-ligne-prix-violet annuel select">1er paiement</p>
                <p class="_2eme-ligne-prix-violet select">de {{ $store.api.prix(souscription.prix_annuel + souscription.frais_adhesion) }}</p>
              </div>
              <p class="description-prix select">Ce paiement inclus votre première annuité ainsi que 40€ de frais d&#x27;adhésion.</p>
              <a href="javascript:void(0)" class="bouton-select-prix selectionne w-button"></a>
            </div>
            <div class="div-paiement-annuel" v-else>
              <div>
                <p class="paiement">Paiement annuel</p>
                <p class="prix">{{$store.api.prix(souscription.prix_annuel)}}/an</p>
                <p class="prix-sous-titre">au lieu de {{ $store.api.prix(souscription.prix_mensuel * 12) }}/an</p>
              </div>
              <div>
                <p class="_1ere-ligne-prix-violet annuel">1er paiement</p>
                <p class="_2eme-ligne-prix-violet">de {{ $store.api.prix(souscription.prix_annuel + souscription.frais_adhesion) }}</p>
              </div>
              <p class="description-prix">Ce paiement inclus votre première annuité ainsi que 40€ de frais d&#x27;adhésion.</p>
              <a href="javascript:void(0)" @click="periode('annuel')" class="bouton-select-prix w-button">sélectionner</a>
            </div>
          </div>          
          <div class="form_paiement w-form" id="form_paiement" v-if="souscription.paiement.periode != null">
            <form ref="form_parcour" id="form-paiement" name="wf-form-Form-paiement" data-name="Form paiement" method="get" class="form-2">
              <div class="div_content_moyen_paiement">
                <div class="div_zone_info_v2">
                  <div class="div_titre_bloc paiement">
                    <p class="p_txt_field_form_v2 small violet">Moyen de paiement</p>
                  </div>
                  <div class="div_recap_infos">
                    <select v-model.number="souscription.paiement.type_id" id="Moyen-paiement" name="Moyen-paiement" data-name="Moyen paiement" required="" class="select_moyen_paiement w-select">
                      <option :value="2">Carte de crédit / débit</option>
                      <!-- <option :value="1">Prélèvement bancaire (SEPA)</option> -->
                    </select>
                  </div>
                  <div  v-if="souscription.paiement.type_id === 1">
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">Titulaire du compte</p>
                      <div class="div_champs_recap_infos">
                        <input type="text" v-model="rib.titulaire" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom-rib" data-name="Nom rib" placeholder="Nom et Prénom" id="Nom-rib" required="">
                      </div>
                    </div>
                    <!-- <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">BIC</p>
                      <div class="div_champs_recap_infos">
                        <input type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Bic-rib" data-name="Bic rib" placeholder="Indiquez votre BIC" id="Bic-rib" required="">
                      </div>
                    </div> -->
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">IBAN</p>
                      <div class="div_champs_recap_infos">
                        <input type="text" class="champs_recap_infos _100 w-input" v-model="rib.iban" maxlength="33" name="Iban-rib" data-name="Iban rib" placeholder="Indiquez votre IBAN" id="Iban-rib" required="">
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _270px_width">N° de votre carte de crédit</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="cb.nb" type="text" class="champs_recap_infos _100 w-input" maxlength="19" name="Numero-cb" data-name="Numero cb" placeholder="0000 0000 0000 0000" id="Numero-cb" required="">
                        <img v-if="checkCB() != false" width="40" loading="lazy" :src="require('@/assets/images/'+checkCB())" alt="" class="image-6" />
                      </div>
                    </div>
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">Nom sur votre carte</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="cb.name"  type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom-cb" data-name="Nom cb" placeholder="Nom et Prénom" id="Nom-cb" required="">
                      </div>
                    </div>
                    <div class="div_valid_infos_horizontal">
                      <div class="div_recap_infos _100 no_margintop">
                        <p class="p_txt_field_form_v2 light _250px_width">Date d&#x27;expiration</p>
                        <input v-model="cb.date"  type="text" class="champs_recap_infos _100 w-input" maxlength="7" name="Date-expiration-cb" data-name="Date expiration cb" placeholder="Mois/Année" id="Date-expiration-cb" required="">
                      </div>
                      <div class="div_recap_infos _100 no_margintop _25px_margin_top">
                        <p class="p_txt_field_form_v2 light _250px_width">CVV</p>
                        <input v-model="cb.cvv" type="text" class="champs_recap_infos _100 w-input" maxlength="4" name="Cvv-cb" data-name="Cvv cb" placeholder="0000" id="Cvv-cb" required="">
                      </div>
                    </div>                  
                  </div>                  
                </div>
              </div>
              <label class="w-checkbox checkbox-accepter">
                <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-2 " :class="optinn ? 'w--redirected-checked' : ''" @click="optinn = !optinn"></div>
                <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required="" style="opacity:0;position:absolute;z-index:-1">
                <span class="checkbox-label-2 w-form-label" for="checkbox">J&#x27;accepte la <a href="https://pautions.fr/charte-protection-donnees-personnelles.html" target="_blank" class="link-text-info"><span class="text-span-13">charte de protection des données personnelles</span></a> et les <a href="https://pautions.fr/conditions-utilisation.html" target="_blank" class="link-text-info"><span class="text-span-14">conditions générales d&#x27;utilisation</span></a>.</span>
              </label>
              <div>
                <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="pay()">VALIDER LE PAIEMENT</a>
                <span v-if="spinner === true"><img class="custom-spinner" :src="require('@/assets/images/spinner.svg')"/><span style="color:rgb(251, 177, 205);font-weight:bold;">Merci de patienter pendant la génération de votre contrat.</span></span>
              </div>
              <div class="bandeau_info_paiement">
                <p class="txt_info_paiement first"><span class="text-span-48">Rappel :</span> votre 1<sup>er</sup> paiement <span class="text-span-50">immédiat</span> à la souscription est de <span class="text-span-49">{{ $store.api.prix(cotisation + souscription.frais_adhesion) }}</span><br></p>
                <p class="txt_info_paiement first _10px_margin_top">Votre second paiement sera de <span class="text-span-51">{{$store.api.prix(souscription.prix_mensuel)}}/mois</span> et sera prélevé 3 mois après la date d&#x27;effet de votre bail, soit le {{ next_pay }}.</p>
              </div>
            </form>            
          </div>
          <div v-if="souscription.paiement.periode != null" class="div-secu-paiement">
            <img :src="require('@/assets/images/ic_lock.svg')" loading="lazy" width="15" alt=""/><p class="info-paiement-securite">Votre paiement est sécurisé et crypté par<span class="text-span-9"></span></p><img :src="require('@/assets/images/ic_stripe.svg')" loading="lazy" width="30" alt="" class="image-7">            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/v2/ParcourLogin';
import moment from 'moment'
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,      
      spinner: false,
      user_lock: true,
      info_lock: true,
      proprio_lock: true,
      optinn: false,
      cb:{
        type_id:2,
        nb:null,
        name:null,
        date:null,
        cvv:null
      },
      rib:{
        type_id:1,
        iban: null,
        titulaire: null,
        bic: null
      }
    }
  },
  watch:{
    'cb.nb'(v){
      if(v === null){
        return false
      }
      this.cb.nb = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'rib.iban'(v){
      if(v === null){
        return false
      }
      this.rib.iban = v.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    },
    'cb.date'(v){
      if(v === null){
        return false;
      }
      if(v.length === 2){
        this.cb.date +="/";
      }
    }
  },
  methods:{
    checkForm(){
      var res = true;
      if(this.$refs.form_parcour.reportValidity() === false){
        res = false;
      }
      return res;
    },
    checkCB(){
      if(this.cb.nb === null){
        return false;
      }
      var cc = this.cb.nb;
      cc = cc.replaceAll(" ","");
      var res = false;
      var amex = new RegExp('^3[47][0-9]+$');
      var visa = new RegExp('^4[0-9]+$');
      var mastercard = new RegExp('^5[1-5][0-9]+$');
      var mastercard2 = new RegExp('^2[2-7][0-9]+$');
      if (visa.test(cc)) {
        res = 'ic-visa.svg';
      }
      if (amex.test(cc)) {
        res = 'ic_amex.svg';
      }
      if (mastercard.test(cc) || mastercard2.test(cc)) {
        res = 'ic_ mastercard.svg';
      }
      return res;
    },
    pay(){
      var the = this;
      if(this.checkForm() === false || this.optinn === false){
        this.$toastr.e("Merci de renseigner tous les champs.");
        return false
      }
      if(this.spinner === true){
        return false;
      }
      var params = {
        user: this.user,
        souscription: this.souscription,
        paiement: this.souscription.paiement.type_id === 1 ? this.rib : this.cb
      };
      this.spinner = true;
      this.$store.api.ajax('/pay/make', params, (res) => {
        if(!res.data.finish && res.data.action === '3ds'){
          var tmp = atob(res.data.query);
          tmp = tmp.split(":");
          var stripe = window.Stripe(tmp[0]);
          stripe.handleCardPayment(tmp[1]).then(function(pay) {  
            console.log(pay);          
            if(pay?.paymentIntent?.status === "succeeded"){
              the.sign();
            }else{
              the.$toastr.e("Une erreur c'est produit, merci de ressayer !");
              the.spinner = false;
            }
          });
        }else if(res.data.finish === true){
          console.log("ffd");
          the.sign();
        }else{
          this.spinner = false;
        }        
      });
    }, 
    sign(){      
      var params = {
        uuid: this.souscription.uuid
      }
      this.$store.api.ajax('/sign/locataire/link', params, res => {
        if(res.status){
          location.href = res.data;
        }
        this.spinner = false;
      })
    },
    periode(type){
      this.souscription.paiement.periode = type;
      setTimeout(() => {      
        document.querySelector('#form_paiement').scrollIntoView({
          behavior: 'smooth'
        });          
      },500)      
    }
  },
  computed: {   
    cotisation(){
      var mt = 0;
      if(this.souscription.paiement.periode === 'mensuel'){
        mt = this.souscription.prix_mensuel * 3
      }else{
        mt = this.souscription.prix_annuel;
      }
      return mt;
    },
    next_pay(){
      var coef = this.souscription.paiement.periode === 'mensuel' ? 3 : 12;
      var date = moment(this.souscription.date_effet).add(coef, 'M');
      return date.format("DD/MM/YYYY")
    }
  },
  mounted() {
    this.ready = true;    
    if(this.souscription.paiement.type_id === null){
      this.souscription.paiement.type_id = 2
    }
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
.bouton-select-prix.selectionne {
  background-color: #242089 !important;
  background-image: url(/img/ic_check.88c852af.svg) !important;
  background-position: 50% !important;
  background-repeat: no-repeat !important;
  background-size: 35px !important;
  padding-top: 27px !important;
}
.custom-spinner {
  max-width: 60px;
  vertical-align: middle;
}
.select_moyen_paiement {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>

<style>
</style>
