<template>
<div>
   <ZeroDepot :souscription="souscription" :user="user"/>
   <div class="section_form_v2 wf-section">
      <div class="content_form_v2 w-container">
         <div class="w-layout-grid grid_form_v2">
          <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-dd72dd49" class="div_precedent_v2"> 
            <a href="/#/accueil" class="arrow_back_v2 w-inline-block"></a> 
          </div>
            <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-4fce5494" class="div_content_form_v2">
               <div id="parcours-client" class="from_parcours_client_v2 w-form">
                  <form ref="form_parcour" name="wf-form-Parcours-client" id="parcours-client-form" class="div_parcours_client_v2">
                     <div class="div_txt_form_v2">
                        <p class="p_txt_field_form_v2">Indiquez les informations de votre futur logement :</p>
                     </div>
                     <div class="div_champs_form_v2 info_logement">
                        <p class="p_txt_field_form_v2 light info_logement">Adresse du futur logement <span class="asterix">*</span></p>
                        <vue-google-autocomplete id="map" classname="champs_form_v2 _70 w-input" placeholder="Adresse" v-on:placechanged="getAddressData" country="fr"> </vue-google-autocomplete>                        
                     </div>
                     <div class="div_champs_form_v2 info_logement">
                        <p class="p_txt_field_form_v2 light info_logement">Code postal et ville <span class="asterix">*</span></p>
                        <input type="text" v-model="souscription.logement.cp" class="champs_form_v2 _35 w-input" maxlength="256" name="Code-postal" data-name="Code postal" placeholder="00000" id="Code-postal" required="">
                        <input type="text"  v-model="souscription.logement.ville" class="champs_form_v2 _35 w-input" maxlength="256" name="Ville" data-name="Ville" placeholder="Ville" id="Ville" required="">
                     </div>
                     <div class="div_champs_form_v2 info_logement">
                        <p class="p_txt_field_form_v2 light info_logement">Complément d&#x27;adresse</p>
                        <input type="text" v-model="souscription.logement.adresse2" class="champs_form_v2 _70 w-input" maxlength="256" name="Complement-adresse" data-name="Complement adresse" placeholder="Ex : bâtiment, résidence..." id="Complement-adresse">
                     </div>
                     <div class="div_champs_form_v2 info_logement">
                        <p class="p_txt_field_form_v2 light info_logement">Date de prise d&#x27;effet du bail <span class="asterix">*</span></p>
                        <input type="date" :min="min_date" v-model="souscription.logement.date_bail" class="champs_form_v2 _70 w-input v2-date" maxlength="256" name="Date-effet-bail" data-name="Date effet bail" placeholder="09/04/2023" id="Date-effet-bail" required="">
                     </div>
                     <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">CONTINUER</a> 
                  </form>            
               </div>
            </div>
         </div>
    </div>
  </div>
</div> 
</template>
<script>
import ZeroDepot from '@/components/Menu/v2/ParcourLogin';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
  name: "Step1",
  components: {
    ZeroDepot, VueGoogleAutocomplete
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      load: false,      
    }
  },
  watch:{
   'souscription.loyer'(){
      this.price();
    }
  },
  methods:{
    checkForm(){
      var res = true;
      if(this.$refs.form_parcour.reportValidity() === false){
        res = false;
      }
      return res;
    },
    getAddressData(q) {
      var d = document.getElementById("map");
      var adresse = '';
      adresse += q.street_number != undefined ? q.street_number : '';
      adresse += q.route != undefined ? ' ' + q.route : '';
      if (adresse != '') {
        this.souscription.logement.adresse = adresse;
        d.value = adresse;
      }
      if (q.locality != undefined) {
        this.souscription.logement.ville = q.locality;
      }
      if (q.postal_code != undefined) {
        this.souscription.logement.cp = q.postal_code;
      }
      this.souscription.logement.lon = q.longitude;
      this.souscription.logement.lat = q.latitude;
   },  
   next(){      
      if(this.checkForm() === false || this.load){
         return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
          this.$parent.next();
        }
      });  
    },  
    price(){
      this.$store.api.ajax('/zero-depot/prix', this.souscription, res => {
        if(res.status === true){
          this.souscription.prix_mensuel = res.data;
          this.souscription.prix_annuel = res.data === null ? null : this.souscription.prix_mensuel * 11;          
        }
      })  
    }
  },
  computed: {
   min_date() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return `${year}-${month}-${day}`
   }
  },
  mounted() {
    this.ready = true;
    setTimeout(() => {
      if(this.souscription.logement.adresse != null){
        var d = document.getElementById("map");
        d.value = this.souscription.logement.adresse;
      }
    },500);
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
</style>

<style scoped>
#Type-Location{
  appearance: none;
}
.txt_modifier.lock {
   width: 100%;
   background-color: #fff;
   background-image: url(/img/lock.847d4763.svg);
   /* background-position: 100% 50%; */
   background-size: 11px;
   background-repeat: no-repeat;
}
.champs_recap_infos {
   background-image: none !important;
}
@media screen and (max-width: 767px){
  .v2-date {    
    width: 550px !important;
  } 
  input.v2-date::-webkit-date-and-time-value {
    text-align: left !important;
  }
}
</style>
