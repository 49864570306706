<template>
<div>
   <ZeroDepot :souscription="souscription" :user="user"/>
   <div class="section_form_v2 wf-section">
    <div class="content_valid_v2 w-container">
      <div class="w-layout-grid grid_valid_v2">
        <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-fab6d4b1" class="div_precedent_v2"> <a href="javascript:void(0)" @click="$parent.previous()" class="arrow_back_v2 w-inline-block"></a> </div>
        <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-fab6d4b1" class="div_content_valid_v2">
          <div id="parcours-client" class="from_parcours_client_v2 w-form">
            <form ref="form_parcour" name="wf-form-Parcours-client" data-name="Parcours client" redirect="/parcours-v2-08" data-redirect="/parcours-v2-08" method="get" id="parcours-client-form" class="div_valid_info_v2">
              <div class="div_txt_valid_v2">
                <p class="p_txt_field_form_v2">Validez les informations relative à votre contrat<span class="text-span-33"></span></p>
              </div>
              <div class="div_content_blocs_valid">
                <div class="div_zone_info_v2">
                  <div class="div_titre_bloc">
                    <p class="p_txt_field_form_v2 small violet">Vos coordonnées :</p>
                    <div class="div_input_modifier">                                  
                      <a href="javascript:void(0)" @click="user_lock = !user_lock" :class="user_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ user_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">Nom :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="user.nom" :disabled="user_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom" id="Nom" required="">
                      </div>
                    </div>
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">Prénom :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="user.prenom" :disabled="user_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Prenom" data-name="Prenom" placeholder="Prenom" id="Prenom" required="">
                      </div>
                    </div>
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">N° de téléphone :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="user.telephone" :disabled="user_lock" type="tel" class="champs_recap_infos _100 w-input" minlength="10" maxlength="10" name="Telephone" data-name="Telephone" placeholder="0102030405" id="Telephone">
                      </div>
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100 no_margintop">
                      <p class="p_txt_field_form_v2 light _250px_width">Email :</p>
                      <input v-model="user.email" :disabled="user_lock" type="email" class="champs_recap_infos _100 w-input" maxlength="256" name="Email" data-name="Email" placeholder="monmail@mail.com" id="Email" required=""> 
                    </div>
                    <div class="div_recap_infos _100 no_margintop _25px_margin_top">
                      <p class="p_txt_field_form_v2 light _250px_width">Date de naissance :</p>
                      <input v-model="user.date_naissance" :max="getDate18" :disabled="user_lock" type="date" class="champs_recap_infos _100 w-input v2-date " maxlength="256" name="Date-de-naissance" data-name="Date de naissance" placeholder="15/10/2001" id="Date-de-naissance" required=""> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="div_content_blocs_valid">
                <div class="div_zone_info_v2">
                  <div class="div_titre_bloc">
                    <p class="p_txt_field_form_v2 small violet">Informations de votre futur logement :</p>
                    <div class="div_input_modifier">                                  
                      <a href="javascript:void(0)" @click="info_lock = !info_lock" :class="info_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ info_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">Type de location :</p>
                      <div class="div_champs_recap_infos">
                        <select v-model="souscription.meublee" :disabled="info_lock" id="Type-location" name="Type-location" data-name="Type location" required="" class="champs_recap_infos _100 select w-select">
                          <option :value="false">Vide</option>
                          <option :value="true">Meublée</option>
                        </select>
                      </div>
                    </div>
                    <div class="div_recap_infos">
                      <p class="p_txt_field_form_v2 light _250px_width">Montant du loyer :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="souscription.loyer" :disabled="info_lock" type="number" max="3000" class="champs_recap_infos _100 w-input" maxlength="256" name="Montant-loyer" data-name="Montant loyer" placeholder="700€" id="Montant-loyer" required="">
                      </div>
                    </div>
                    <div class="div_recap_infos" v-if="souscription.apt_ref != null">
                      <p class="p_txt_field_form_v2 light _250px_width">Code appartement <span class="text-span-35">(facultatif)</span></p>
                      <div class="div_champs_recap_infos">
                        <input v-model="souscription.apt_ref" :disabled="info_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Code-appartement" data-name="Code appartement" placeholder="" id="Code-appartement">
                      </div>
                    </div>
                  </div>
                  <div class="div_recap_infos">
                    <p class="p_txt_field_form_v2 light">Adresse postale :</p>
                    <div class="div_champs_recap_infos">
                      <!-- <input v-model="souscription.logement.adresse" :disabled="info_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Adresse" data-name="Adresse" placeholder="10 rue de Villiers, 92300 Levallois Perret" id="Adresse" required=""> -->
                      <vue-google-autocomplete :disabled="info_lock" id="map" classname="champs_recap_infos _100 w-input" placeholder="Adresse" v-on:placechanged="getAddressData" country="fr"> </vue-google-autocomplete>                        
                    </div>
                  </div>
                  <div class="div_recap_infos">
                    <p class="p_txt_field_form_v2 light" style="width: 100%;">Complément d&#x27;adresse : <span class="text-span-35">(facultatif)</span></p>
                    <div class="div_champs_recap_infos">
                      <input v-model="souscription.logement.adresse2" :disabled="info_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Adresse-complement" data-name="Adresse complement" placeholder="" id="Adresse-complement">
                    </div>
                  </div>
                </div>
              </div>
              <div class="div_content_blocs_valid" v-if="souscription.agence === false">
                <div class="div_zone_info_v2">
                  <div class="div_titre_bloc">
                    <p class="p_txt_field_form_v2 small violet">Coordonnées propriétaire : </p>
                    <div class="div_input_modifier">                                  
                      <a href="javascript:void(0)" @click="proprio_lock = !proprio_lock" :class="proprio_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ proprio_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">Nom :</p>
                      <div class="div_champs_recap_infos">
                        <input  v-model="proprietaire.nom" :disabled="proprio_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom-proprio" data-name="Nom proprio" placeholder="Nom" id="Nom-proprio" required="">
                      </div>
                    </div>
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">Prénom :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="proprietaire.prenom" :disabled="proprio_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Prenom-proprio" data-name="Prenom proprio" placeholder="Prenom" id="Prenom-proprio" required="">
                      </div>
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100 no_margintop">
                      <p class="p_txt_field_form_v2 light _250px_width">Email :</p>
                      <input v-model="proprietaire.email" :disabled="proprio_lock" type="email" class="champs_recap_infos _100 w-input" maxlength="256" name="Email-proprio" data-name="Email proprio" placeholder="monmail@mail.com" id="Email-proprio" required=""> 
                    </div>
                    <div class="div_recap_infos _100 no_margintop _25px_margin_top">
                      <p class="p_txt_field_form_v2 light _250px_width">N° de téléphone <span class="text-span-35">(facultatif)</span>:</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="proprietaire.telephone" :disabled="proprio_lock" type="tel" class="champs_recap_infos _100 w-input" minlength="10" maxlength="10" name="Telephone-proprio" data-name="Telephone proprio" placeholder="" id="Telephone-proprio">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="div_content_blocs_valid" v-else>
                <div class="div_zone_info_v2">
                  <div class="div_titre_bloc">
                    <p class="p_txt_field_form_v2 small violet">Coordonnées gestionnaire immobilier : </p>
                    <div class="div_input_modifier">                                  
                      <a href="javascript:void(0)" @click="proprio_lock = !proprio_lock" :class="proprio_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ proprio_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                    </div>
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light _250px_width">Nom agence :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="proprietaire.nom" :disabled="proprio_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom-agence" data-name="Nom agence" placeholder="Agence" id="Nom-agence" required="">
                      </div>
                    </div>
                    <!-- <div class="div_recap_infos _100">
                      <p class="p_txt_field_form_v2 light" style="width: 100%;">Nom agent immobilier <span class="text-span-35">(facultatif)</span>:</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="proprietaire.prenom" :disabled="proprio_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom-agent" data-name="Nom agent" placeholder="" id="Nom-agent">
                      </div>
                    </div> -->
                  </div>
                  <div class="div_valid_infos_horizontal">
                    <div class="div_recap_infos _100 no_margintop">
                      <p class="p_txt_field_form_v2 light _250px_width">Email :</p>
                      <input v-model="proprietaire.email" :disabled="proprio_lock" type="email" class="champs_recap_infos _100 w-input" maxlength="256" name="Email-agence" data-name="Email agence" placeholder="agence@mail.com" id="Email-agence" required=""> 
                    </div>
                    <div class="div_recap_infos _100 no_margintop _25px_margin_top">
                      <p class="p_txt_field_form_v2 light _250px_width">N° de téléphone :</p>
                      <div class="div_champs_recap_infos">
                        <input v-model="proprietaire.telephone" :disabled="proprio_lock" type="tel" class="champs_recap_infos _100 w-input" minlength="10" maxlength="10" name="Telephone-agence" data-name="Telephone agence" placeholder="0102030405" id="Telephone-agence" required="">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="docs_infos_valid">
                <p class="titre-doc-infos">Documents d&#x27;informations :</p>
                <div class="div-block-25"> 
                  <a :href="$store.api.urlAPI+'/docs/CG.pdf'" target="_blank" class="txt_docs_infos">Conditions générales </a> 
                  <a :href="$store.api.urlAPI+'/docs/idip.pdf'" target="_blank" class="texte-doc-infos">Notice d&#x27;information et garanties</a> 
                  <a :href="$store.api.urlAPI+'/docs/PJ.pdf'" target="_blank" class="texte-doc-infos">Protection juridique</a> </div>
              </div>
              <div class="div_content_blocs_valid">
                <div class="div_zone_info_loyer_v2 violet valid_infos">
                  <div class="div_titre_bloc _30px_gap_c">
                    <p class="p_txt_field_form_v2 small blanc">Montant de votre assurance Zéro Dépôt :</p>
                    <p class="p_txt_field_form_v2 small violet prix blanc">{{$store.api.prix(souscription.prix_mensuel)}}/mois <span class="text-span-37" v-if="souscription.coup_dur">(dont option Coup Dur à {{ $store.api.prix(getCoupDur) }})</span></p>
                  </div>
                </div>
              </div>
              <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">VALIDER MES INFORMATIONS</a>               
            </form>          
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/v2/ParcourLogin';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
  name: "Step1",
  components: {
    ZeroDepot, VueGoogleAutocomplete
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      load: false,
      user_lock: true,
      info_lock: true,
      proprio_lock: true
    }
  },
  watch:{
   'souscription.loyer'(){      
      this.price();
    },    
    'souscription.meublee'(){
      this.price();
    },
    'proprietaire.telephone'(){
      if(this.proprietaire.telephone === null){
        return false;
      }
      this.proprietaire.telephone = this.proprietaire.telephone.replaceAll(" ","");
    },
    'info_lock'(){
      setTimeout(() => {
        var d = document.getElementById("map");        
        d.value = this.souscription.logement.adresse+', '+this.souscription.logement.cp+' '+this.souscription.logement.ville;                
      },300)
    }
  },
  methods:{
    async checkForm(){
      this.user_lock = false;
      this.info_lock = false;
      this.proprio_lock = false;
      var res = true;      
      await new Promise(resolve => {
         setTimeout(() => {            
            if(this.$refs.form_parcour.reportValidity() === false){
               res = false;
            }
            resolve();
         }, 500);
      });      
      return res;
    }, 
    getAddressData(q) {
      var d = document.getElementById("map");
      var adresse = '';
      adresse += q.street_number != undefined ? q.street_number : '';
      adresse += q.route != undefined ? ' ' + q.route : '';
      if (adresse != '') {
        this.souscription.logement.adresse = adresse;
        d.value = adresse;
      }
      if (q.locality != undefined) {
        this.souscription.logement.ville = q.locality;
      }
      if (q.postal_code != undefined) {
        this.souscription.logement.cp = q.postal_code;
      }
      this.souscription.logement.lon = q.longitude;
      this.souscription.logement.lat = q.latitude;
   },  
   async next(){            
      if(await this.checkForm() === false || this.load){
         return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription,
        proprietaire: this.proprietaire
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
          this.$parent.next();
        }
      });  
    },    
    price(){
      if(this.souscription.loyer > 3000){
        return false;
      }
      this.$store.api.ajax('/zero-depot/prix', this.souscription, res => {
        if(res.status === true){
          this.souscription.prix_mensuel = res.data;
          this.souscription.prix_annuel = res.data === null ? null : this.souscription.prix_mensuel * 11;          
        }
      })
    }
  },
  computed: {
    min_date() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return `${year}-${month}-${day}`
    },
    getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day
      return `${year}-${month}-${day}`
    },
    getCoupDur(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee === true){
        tmp = tmp*2;
      }
      tmp = (tmp*5.67)/100
      tmp = tmp/12;
      tmp = tmp*1.15;
      return Math.ceil(tmp);
    }   
  },
  mounted() {
    this.ready = true;
    setTimeout(() => {
      var d = document.getElementById("map");        
      d.value = this.souscription.logement.adresse+', '+this.souscription.logement.cp+' '+this.souscription.logement.ville;                
    },300)   
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
@media screen and (max-width: 767px){
  .v2-date {    
    width: 550px !important;
  }
  input.v2-date::-webkit-date-and-time-value {
    text-align: left !important;
  }
}
</style>

<style >
#Type-Location{
  appearance: none;
}
.txt_modifier.lock {
   width: 100%;
   background-color: #fff;
   background-image: url(/img/lock.847d4763.svg);
   /* background-position: 100% 50%; */
   background-size: 11px;
   background-repeat: no-repeat;
}
.champs_recap_infos {
   background-image: none;
}
input[disabled], select[disabled] {
   background-color: white !important;
   color: #7170ab !important;
}
@supports (-webkit-touch-callout: none) {
   .w-input[disabled] , .w-select[disabled]{
      background-color: white !important;
      color: #19187e !important;
   }
 }
</style>
