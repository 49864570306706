<template>
  <div>
   <ZeroDepot :souscription="souscription"/>
   <!-- <div id="ZONE-INFO-ATTESTATION" class="info_ap_v2 wf-section">
      <div class="content_ap_v2 w-container">
         <div class="w-layout-grid grid_ap_v2">
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630546-00bbca5b" class="div_img_info_ap_v2"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="img_info_ap"></div>
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630548-00bbca5b" class="div_txt_info_ap_v2">
               <p class="p_info_ap_v2">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
               <p class="p_info_ap_v2 light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
            </div>
         </div>
      </div>
   </div> -->
   <div class="section_form_v2 wf-section">
      <div class="content_form_v2 w-container">
         <div class="w-layout-grid grid_form_v2">
            <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-8a72dd4" class="div_precedent_v2"></div>
            <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-8a72dd48" class="div_content_form_v2">
               <div id="parcours-client" class="from_parcours_client_v2 w-form">
                  <form id="parcours-client-form" class="div_parcours_client_v2" ref="form_parcour" @submit="handleSubmit">
                     <div class="div_txt_form_v2">
                        <p class="p_txt_field_form_v2">Vous recherchez ou vous avez trouvé un logement en location</p>
                        <select id="Type-Location" v-model="souscription.meublee" required class="select_type_location_v2 w-select">
                          <option :value="false">vide</option>
                          <option :value="true">meublée</option>
                        </select>
                     </div>
                     <div class="div_txt_form_v2 mtop">
                        <p class="p_txt_field_form_v2">Pour un loyer de</p>
                        <input type="number" class="txt_field_v2 euro w-input" min="1" max="3000" v-model.number="souscription.loyer" maxlength="256"  placeholder="1 000" required=""> 
                     </div>
                     <div class="bloc_info_v2" v-if="souscription.loyer > 3000">
                        <p class="txt_info_loyer_v2">Le montant que vous avez renseigné <span class="text-span-29">n&#x27;est pas éligible</span> et dépasse la prise en charge de l&#x27;assurance Zéro Dépôt.</p>
                     </div>                     
                     <div class="div_montant_azd fade-in " v-if="cotisation != null">
                        <p class="montant_calculateur_text">Montant de l’assurance Zéro Dépôt</p>
                        <p class="prix_montant_azd">{{$store.api.prix(cotisation)}}/mois</p>
                        <p class="montant_calculateur_text dg">au lieu de dépenser <span class="text-span-32">{{$store.api.prix(souscription.meublee ? souscription.loyer * 2 : souscription.loyer)}}</span> pour un dépôt de garantie.<br></p>
                     </div>                     
                     <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">CONTINUER</a>                      
                  </form>                  
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire', 'params'],
  data(){
    return {
      ready:false,
      cotisation:null
    }
  },
  watch:{
    'souscription.loyer'(){
      this.price();
    },
    'souscription.meublee'(){
      this.price();
    }
  },
  methods:{
    handleSubmit(event) {
      event.preventDefault();
    },
    checkForm(){
      var res = true;
      if(this.$refs.form_parcour.reportValidity() === false){
        res = false;
      }
      return res;
    },
    price(){
      this.$store.api.ajax('/zero-depot/prix', this.souscription, res => {
        if(res.status === true){
          this.cotisation = res.data;
        }
      })
    },
    next(){
      if(!this.checkForm()){
        return false;
      }
      this.$parent.next();
    }
  },
  computed: {    
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style>
#Type-Location{
  appearance: none;
}
.euro.w-input {
  background-image:  url('~@/assets/images/ic_euro_2.svg');
  background-position: 95%;
  text-align: left !important;
  background-repeat: no-repeat;
  background-size: auto;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
