<template>
  <div>
   <ZeroDepot :souscription="souscription"/>   
   <div class="section_form_v2 wf-section">
      <div class="content_form_v2 w-container">
         <div class="w-layout-grid grid_form_v2">
            <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-8a72dd4" class="div_precedent_v2"></div>
            <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-8a72dd48" class="div_content_form_v2">
               <div class="card-wait">
                  <p class="title">Mise à jour importante concernant <br/>l'Assurance Zéro Dépôt</p>
                  <br/>
                  <p>Chère Cliente, Cher Client,</p>
                  <p>Nous souhaitons vous informer d'une évolution importante liée à l'Assurance Zéro Dépôt. Dans le cadre de notre engagement à vous offrir des services de qualité et adaptés à vos besoins, nous avons pris la décision de changer de partenaire assureur. <strong>Par conséquent, les souscriptions à l'Assurance Zéro Dépôt seront temporairement suspendues.</strong></p>
                  <p>Nous tenons à vous rassurer que cette suspension est temporaire et que nous travaillons activement à nouer un partenariat avec un nouvel assureur afin de réactiver le service.</p>
                  <p>Soyez assurés que toutes les souscriptions déjà effectuées restent pleinement valides et continueront d’être gérées conformément aux conditions initiales. Cette transition n’aura aucun impact sur les garanties en cours, et nous vous garantissons que l’ensemble des engagements pris seront respectés.</p>
                  <p>Nous vous tiendrons informés dès que les souscriptions seront de nouveau disponibles. Nous vous remercions pour votre confiance et restons à votre disposition pour toute question ou besoin d'éclaircissements.</p>
                  <p>Cordialement<br/>L'équipe de Pautions.</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire', 'params'],
  data(){
    return {
      ready:false,
      cotisation:null
    }
  },
  watch:{    
  },
  methods:{    
  },
  computed: {    
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style>
.card-wait{
  text-align: center;  
  padding: 0px 100px;
}
.card-wait p.title{
  font-size: 35px;
  line-height:40px;
  font-weight:700
}
.card-wait p {
  font-size: 23px;
  line-height:35px;
}
.w-layout-grid.grid_form_v2{
  background-image: url('https://cdn.prod.website-files.com/6405f5614fee64e96b9f59f0/6405f5614fee64ad8a9f5ae2_header_apropos-min%20(2)%20(1).png');
  margin-bottom: 0;
  padding-bottom: 34.4rem;
  padding-left: 5%;
  padding-right: 5%;
}
@media screen and (max-width: 767px){
  .card-wait {
    padding: initial;
  }
  .card-wait p.title{
    font-size: 20px;
    line-height:20px;    
  }
  .card-wait p {
    font-size: 17px;
    line-height:20px;
  }
}
</style>
