<template>
  <div class="header-form-connect wf-section">
    <div class="container-header-form-connect w-container">
      <div class="w-layout-grid grid-header-form-connect">
        <div id="w-node-_17e0abe6-8a9a-360a-33f4-d1a87afb1bf4-7afb1bf1" class="div-precedent-mobile-connect">
          <a @click="prev()" class="zone-precedent-mobile w-inline-block" v-if="prevFlag === true"></a>
          <a href="/#/accueil" class="zone-precedent-mobile w-inline-block" v-else-if="souscription.last_step === 4"></a>
        </div>
        
        <div id="w-node-_17e0abe6-8a9a-360a-33f4-d1a87afb1bf6-7afb1bf1" class="zone-logo-form-connect">
          <a href="https://pautions.fr" target="_blank">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" alt="" class="img-logo-connect"><img :src="require('@/assets/images/logo_p_pautions.svg')" loading="lazy" width="29" alt="" class="logo-p-pautions">
          </a>
        </div>
        <div id="w-node-_17e0abe6-8a9a-360a-33f4-d1a87afb1bf8-7afb1bf1" class="zone-info-azd-connect">
          <p class="paragraph-info-azd-connect info-azd">Assurance Zéro Dépôt</p>
        </div>
        <div id="w-node-_35a66947-bed9-4092-f214-6495ca6f8528-7afb1bf1" class="div-zone-connexion-header">
          <div class="zone-nom-parcours">
            <div class="cercle-id-parcours">
              <p class="id-nom-parcours">{{initial}}</p>
            </div>
            <p class="nom-id-parcours">{{user.prenom}} {{user.nom}}<br></p>
          </div>
          <a href="/#/login" class="bouton-deconnexion w-inline-block"><img :src="require('@/assets/images/ic_deconnexion.svg')" loading="lazy" width="18" alt="" class="ic-deconnexion">
            <p class="txt-deconnexion">se déconnecter</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['souscription', 'user'],
  name: "ZeroDepotLogin",
  data: () => ({

  }),
  computed:{
    initial(){
      var tmp = ""
      tmp += this.user.prenom.toUpperCase()[0]
      tmp += this.user.nom.toUpperCase()[0];
      return tmp;
    },
    prevFlag(){
      var res = true;
      if(this.souscription.last_step === 4){
        res = false;
      }   
      return res;   
    }
  },
  methods: {
    prev() {
      this.souscription.last_step--
    }
  },
  mounted() {    
  }
}
</script>
<style lang="scss" scoped>
</style>
