<template>
  <div class="header-form-light wf-section">
    <div class="container-header-form w-container">
      <!-- <div class="w-layout-grid" :class="souscription.last_step === 1 ? 'grid-header-form-start' : 'grid-header-form'"> -->
      <div class="w-layout-grid grid-header-form">
        <div id="w-node-_8fcdc269-b47d-5891-c18a-eb51579e74ee-5995a61b" class="div-precedent-mobile" :class="{'hidden': prevFlag === false}">
          <a @click="prev()" class="zone-precedent-mobile w-inline-block"></a>
        </div>
        <div id="w-node-_0ca39bfd-ac48-8495-0661-dd075995a61e-5995a61b" class="zone-logo-form">
          <a href="https://pautions.fr" target="_blank">
            <img :src="require('@/assets/images/logo_pautions.svg')" loading="lazy" width="120" alt="">
          </a>
        </div>
        <div id="w-node-_0ca39bfd-ac48-8495-0661-dd075995a620-5995a61b" class="zone-info-azd">
          <p class="paragraph-info-azd info-azd">Assurance Zéro Dépôt</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['souscription'],
  name: "ZeroDepot",
  computed:{
    prevFlag(){
      var res = true;
      if(this.souscription.last_step === 1){
        res = false;
      }
      if(this.souscription.provisoir === true && this.souscription.last_step === 7){
        res = false;
      }
      if(this.souscription.provisoir === false && this.souscription.last_step === 5){
        res = false;
      }
      if(this.souscription.provisoir === false && this.souscription.last_step === 4 && this.souscription.uuid != null){
        res = false;
      }
      return res;
    }
  },
  methods: {
    prev() {
      this.souscription.last_step--
    }
  },
  mounted() {
    //this.$store.api.initMenuMobile();
  }
}
</script>
<style scoped>
.hidden {
  width: 0px;
}
</style>
