<template>
  <div>
   <ZeroDepot :souscription="souscription"/>
   <!-- <div id="ZONE-INFO-ATTESTATION" class="info_ap_v2 wf-section">
      <div class="content_ap_v2 w-container">
         <div class="w-layout-grid grid_ap_v2">
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630546-00bbca5b" class="div_img_info_ap_v2"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="img_info_ap"></div>
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630548-00bbca5b" class="div_txt_info_ap_v2">
               <p class="p_info_ap_v2">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
               <p class="p_info_ap_v2 light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
            </div>
         </div>
      </div>
   </div> -->
   <div class="section_form_v2 wf-section">
      <div class="content_form_v2 w-container">
         <div class="w-layout-grid grid_form_v2">
            <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-dd72dd49" class="div_precedent_v2"> <a href="javascript:void(0)" @click="$parent.previous()" class="arrow_back_v2 w-inline-block"></a> </div>
            <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-dd72dd49" class="div_content_form_v2">
               <div id="parcours-client" class="from_parcours_client_v2 w-form">
                  <form name="wf-form-Parcours-client" id="parcours-client-form" class="div_parcours_client_v2" ref="form_parcour">
                    <div class="div_txt_form_v2">
                      <p class="p_txt_field_form_v2">Indiquez vos coordonnées :</p>
                    </div>
                    <div class="div_champs_form_v2">
                      <p class="p_txt_field_form_v2 light">Je suis <span class="asterix">*</span></p>
                      <input v-model="user.nom" type="text" class="champs_form_v2 _250px w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom" id="Nom" required="">
                      <input v-model="user.prenom" type="text" class="champs_form_v2 _250px w-input" maxlength="256" name="Prenom" data-name="Prenom" placeholder="Prénom" id="Prenom" required="">
                    </div>
                    <div class="div_champs_form_v2">
                      <p class="p_txt_field_form_v2 light" style="width: 100%;">Je suis né(e) le <span class="asterix">*</span></p>
                      <input v-model="user.date_naissance" :max="getDate18" type="date" class="champs_form_v2 _250px w-input v2-date" maxlength="256" name="Date-de-naissance" data-name="Date de naissance" placeholder="jj/mm/aaaa" id="Date-de-naissance" required="">
                    </div>                    
                    <div class="div_champs_form_v2">
                      <p class="p_txt_field_form_v2 light">Mon téléphone <span class="asterix">*</span></p>
                      <input v-model="user.telephone" type="tel" class="champs_form_v2 _250px w-input" maxlength="10" minlength="10" name="Telephone" data-name="Telephone" placeholder="0102030405" id="Telephone" required="">
                    </div>
                    <div class="div_champs_form_v2">
                      <p class="p_txt_field_form_v2 light">Mon mail <span class="asterix">*</span></p>
                      <input v-model="user.email" type="email" class="champs_form_v2 _250px w-input" maxlength="256" name="email" data-name="email" placeholder="monmail@mail.com" id="Email" required="">
                    </div>
                    <div class="div_zone_info_v2 bottom_pad_20px" v-if="params.bloc_code_apt">
                      <p class="p_txt_field_form_v2 small violet">Vous avez trouvé un logement sans dépôt de garantie sur un portail d’annonce immobilier ?</p>
                      <div class="div_code_apt">
                        <p class="p_txt_field_form_v2 light_v2">Indiquez le <span class="violet_souligne">code appartement</span> Pautions présent sur l’annonce :</p><input v-model="souscription.apt_ref" type="text" class="champs_form_v2 _100px w-input" maxlength="256" name="Code-appartement" data-name="Code appartement" placeholder="AZD123456" id="Code-appartement-3">
                      </div>
                    </div>
                    <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">CONTINUER</a> 
                  </form>                  
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template> 
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire', 'params'],
  data(){
    return {
      ready:false,      
    }
  },
  watch:{    
  },
  methods:{
    checkForm(){
      var res = true;
      if(this.$refs.form_parcour.reportValidity() === false){
        res = false;
      }
      return res;
    },
    next(){
      if(this.checkForm() === false || this.load){
        return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
          this.souscription.id = res.data.id;
          this.souscription.prix_mensuel = res.data.prix_mensuel;
          this.souscription.prix_annuel = res.data.prix_annuel;
          this.souscription.user_id = res.data.user_id;
          this.user.id = this.souscription.user_id;
          this.$parent.next();
        }
        this.load = false;
      });      
    }
  },
  computed: { 
    getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day
      return `${year}-${month}-${day}`
    },   
  },
  mounted() {
    this.ready = true;
  }
};
</script>
<style scoped>
#Type-Location{
  appearance: none;
}
.v2-date {
  margin-left: 35px !important;
  width: 650px;  
}
@media screen and (max-width: 991px){
  .v2-date {
    width: 550px !important;
    /* text-align: left !important; */
  }
  input.v2-date::-webkit-date-and-time-value {
    text-align: left !important;
  }
}
@media screen and (max-width: 767px){
  .v2-date {
    margin-left: 0px !important;
    width: 250px !important;
  }
}
@media screen and (max-width: 479px){
  .v2-date {
    margin-left: 0px !important;
    max-width: 550px !important;
  }
}
</style>
