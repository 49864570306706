<template>
  <div>
   <ZeroDepot :souscription="souscription"/>
   <!-- <div id="ZONE-INFO-ATTESTATION" class="info_ap_v2 wf-section">
      <div class="content_ap_v2 w-container">
         <div class="w-layout-grid grid_ap_v2">
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630546-00bbca5b" class="div_img_info_ap_v2"><img :src="require('@/assets/images/ic_info_attestion.svg')" loading="lazy" width="30" alt="" class="img_info_ap"></div>
            <div id="w-node-f6bbe87f-6558-3344-7332-06c6d4630548-00bbca5b" class="div_txt_info_ap_v2">
               <p class="p_info_ap_v2">L&#x27;attestation provisoire est un document gratuit et sans engagement à ajouter à votre dossier de location.</p>
               <p class="p_info_ap_v2 light">Elle vous permet de prouver à votre futur bailleur que vous disposez de garanties solides pour remplacer le dépôt de garantie par l&#x27;assurance Zéro Dépôt.</p>
            </div>
         </div>
      </div>
   </div> -->
   <div class="section_form_v2 wf-section">
      <div class="content_form_v2 w-container">
         <div class="w-layout-grid grid_form_v2">
            <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-4872dd4a" class="div_precedent_v2"> <a href="javascript:void(0)" @click="$parent.previous()" class="arrow_back_v2 w-inline-block"></a> </div>
            <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-4872dd4a" class="div_content_form_v2">
               <div id="parcours-client" class="from_parcours_client_v2 w-form">
                  <form name="wf-form-Parcours-client" id="parcours-client-form" class="div_parcours_client_v2" ref="form_parcour">
                     <div class="div_txt_form_v2">
                        <p class="p_txt_field_form_v2">Définissez votre mot de passe et <span class="text-span-33">connectez-vous à votre espace Pautions.</span></p>
                     </div>
                     <div class="div_content_blocs">
                        <div class="div_zone_info_v2">
                           <div class="div_titre_bloc">
                              <p class="p_txt_field_form_v2 small violet">Vos coordonnées :</p>
                              <div class="div_input_modifier">                                  
                                 <a href="javascript:void(0)" @click="user_lock = !user_lock" :class="user_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ user_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                              </div>
                           </div>
                           <div class="div_recap_infos">
                              <p class="p_txt_field_form_v2 light">Nom &amp; Prénom :</p>
                              <div class="div_champs_recap_infos">
                                <input v-model="user.prenom" :readonly="user_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom" id="Nom" required="">
                                <input v-model="user.nom" :readonly="user_lock" type="text" class="champs_recap_infos _100 w-input" maxlength="256" name="Prenom" data-name="Prenom" placeholder="Prenom" id="Prenom" required="">
                              </div>
                           </div>
                           <div class="div_recap_infos">
                              <p class="p_txt_field_form_v2 light">Email :</p>
                              <div class="div_champs_recap_infos">
                                <input v-model="user.email" :readonly="user_lock" type="email" class="champs_recap_infos _100 w-input" maxlength="256" name="Email" data-name="Email" placeholder="mail@mail.com" id="Email" required="">
                              </div>
                           </div>
                           <div class="div_recap_infos_double">
                              <div class="div_recap_infos">
                                 <p class="p_txt_field_form_v2 light">N° de téléphone :</p>
                                 <div class="div_champs_recap_infos">
                                  <input v-model="user.telephone" :readonly="user_lock" type="tel" class="champs_recap_infos _100 w-input" maxlength="10" minlength="10" name="Telephone" data-name="Telephone" placeholder="0102030405" id="Telephone" required="">
                                </div>
                              </div>
                              <div class="div_recap_infos">
                                 <p class="p_txt_field_form_v2 light _190px_width">Date de naissance :</p>
                                 <div class="div_champs_recap_infos">
                                  <input v-model="user.date_naissance" :max="getDate18" :readonly="user_lock" type="date" class="champs_recap_infos _100 w-input v2-date"  placeholder="11/01/2003" required="">
                                </div>
                              </div>
                           </div>
                        </div>
                        <div class="div_zone_info_v2 mtop_25px">
                           <div class="div_titre_bloc">
                              <p class="p_txt_field_form_v2 small violet">Informations logement :</p>
                              <div class="div_input_modifier">                                  
                                 <a href="javascript:void(0)" @click="info_lock = !info_lock" :class="info_lock ? 'txt_modifier' : 'txt_sauvegarder d-block'">{{ info_lock ? 'Modifier' : 'Sauvegarder' }}</a>  
                              </div>
                           </div> 
                           <div class="div_recap_infos">
                              <p class="p_txt_field_form_v2 light _190px_width">Type de location :</p>
                              <div class="div_champs_recap_infos">
                                 <select  v-model="souscription.meublee" :readonly="info_lock" id="Type-location" name="Type-location" data-name="Type location" required="" class="champs_recap_infos _100 select w-select">
                                    <option :value="false">Vide</option>
                                    <option :value="true">Meublée</option>
                                 </select>
                              </div>
                           </div>
                           <div class="div_recap_infos">
                              <p class="p_txt_field_form_v2 light _250px_width">Montant du loyer estimé :</p>
                              <div class="div_champs_recap_infos">
                                <input type="text" :readonly="info_lock" class="champs_recap_infos _100 w-input euro" name="Montant-loyer" data-name="Montant loyer" id="Montant-loyer" required=""  min="1" max="3000" v-model.number="souscription.loyer" maxlength="256"  placeholder="1 000€">
                              </div>
                           </div>
                           <div class="div_recap_infos">
                              <p class="p_txt_field_form_v2 light _250px_width">Code appartement <span class="text-span-35">(facultatif)</span></p>
                              <div class="div_champs_recap_infos">
                                 <input type="text" class="champs_recap_infos _100 w-input" :readonly="info_lock" maxlength="256" name="Code-appartement" data-name="Code appartement" placeholder="" id="Code-appartement" v-model.number="souscription.apt_ref">
                              </div>
                           </div>                           
                        </div>
                     </div>
                     <div class="div_content_blocs">
                        <div class="bloc_info_v2" v-if="souscription.loyer > 3000">
                           <p class="txt_info_loyer_v2">Le montant que vous avez renseigné <span class="text-span-29">n&#x27;est pas éligible</span> et dépasse la prise en charge de l&#x27;assurance Zéro Dépôt.</p>
                        </div>
                        <div v-else class="div_zone_info_loyer_v2">
                           <div class="div_titre_bloc _30px_gap_c">
                              <p class="p_txt_field_form_v2 small violet">Montant de l&#x27;assurance Zéro Dépôt :</p>
                              <p class="p_txt_field_form_v2 small violet prix">{{ $store.api.prix(souscription.prix_mensuel) }}/mois <span class="text-span-37">au lieu de dépenser {{ $store.api.prix(souscription.meublee ? souscription.loyer * 2 : souscription.loyer) }} de dépôt de garantie</span></p>
                           </div>
                        </div>
                     </div>
                     <div class="div_recap_infos mot_de_passe">                        
                        <div class="champ_mot_de_passe">
                           <input v-model="user.password" type="text" class="champs_recap_infos w-input" maxlength="256" name="Mot-de-passe" data-name="Mot de passe" placeholder="Indiquez votre mot de passe" id="Mot-de-passe" required="">
                           <img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-visible"><img width="25" loading="lazy" src="images/ic_eye_close.svg" alt="" class="mot-de-passe-non-visible">
                        </div>
                     </div>
                     <a v-if="souscription.prix_mensuel != null" data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">SE CONNECTER</a> 
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/ZeroDepot';
export default {
  name: "Step1",
  components: {
    ZeroDepot
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      load: false,
      user_lock: true,
      info_lock: true
    }
  },
  watch:{
   'souscription.loyer'(){
      this.price();
    },
    'souscription.meublee'(){
      this.price();
    }
  },
  methods:{
    async checkForm(){
      this.user_lock = false;
      this.info_lock = false;
      var res = true;
      await new Promise(resolve => {
         setTimeout(() => {            
            if(this.$refs.form_parcour.reportValidity() === false){
               res = false;
            }
            resolve();
         }, 500);
      });      
      return res;
    },
    async next(){      
      if(await this.checkForm() === false || this.load){
        return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
         this.$store.api.saveToken(res.data.auth.token);
         this.$store.api.saveUser(res.data.auth);
         this.$router.push({path:'/v2/accueil'});
        }
      });  
    },    
    price(){
      this.$store.api.ajax('/zero-depot/prix', this.souscription, res => {
        if(res.status === true){
         this.souscription.prix_mensuel = res.data;
         this.souscription.prix_annuel = res.data === null ? null : this.souscription.prix_mensuel * 11;          
        }
      })
    },
  },
  computed: {   
   getDate18(){
      var date = new Date();
      date.setFullYear(date.getFullYear()-18);
      var year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month < 10 ? "0"+month : month
      var day = date.getDate();
      day = day < 10 ? "0"+day : day
      return `${year}-${month}-${day}`
    },    
  },
  mounted() {
   this.user.password = null;
    this.ready = true;
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
</style>

<style scoped>
#Type-Location{
  appearance: none;
}
.txt_modifier.lock {
   width: 100%;
   background-color: #fff;
   background-image: url(/img/lock.847d4763.svg);
   /* background-position: 100% 50%; */
   background-size: 11px;
   background-repeat: no-repeat;
}
.champs_recap_infos {
   background-image: none;
}
.w-input[disabled]:not(.w-input-disabled), .w-select[disabled]:not(.w-input-disabled), .w-input[readonly], .w-select[readonly], fieldset[disabled]:not(.w-input-disabled) .w-input, fieldset[disabled]:not(.w-input-disabled) .w-select {
   background-color: white;
   color: #7170ab;
}

@media screen and (max-width: 767px){
  .v2-date {    
    width: 550px !important;
  }
  input.v2-date::-webkit-date-and-time-value {
    text-align: left !important;
  }
}
@supports (-webkit-touch-callout: none) {
   .w-input[disabled] , .w-select[disabled]{
      background-color: white;
      color: #19187e !important;
   }
 }
</style>
