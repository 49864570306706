<template>
<div>
   <ZeroDepot :souscription="souscription" :user="user"/>
   <div class="section_form_v2 wf-section">
    <div class="content_form_v2 w-container">
      <div class="w-layout-grid grid_form_v2">
        <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-b1f17bb3" class="div_precedent_v2"> <a href="javascript:void(0)" @click="$parent.previous()" class="arrow_back_v2 w-inline-block"></a> </div>
        <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-b1f17bb3" class="div_content_form_v2">
          <div id="parcours-client" class="from_parcours_client_v2 w-form">
            <form ref="form_parcour" name="wf-form-Parcours-client" class="div_parcours_client_v2">
              <div class="div_txt_form_v2">
                <p class="p_txt_field_form_v2">Indiquez les coordonnées de votre futur bailleur :</p>
              </div>
              <div class="div_champs_form_v2 info_logement">
                <p class="p_txt_field_form_v2 light info_logement">Vous signez votre bail avec <span class="asterix">*</span></p>
                <select :readonly="proprietaire.id != null && souscription.agence === true" v-model="souscription.agence" id="Signature-bail" name="Signature-bail" required="" class="champs_form_v2 _70 select w-select">
                  <option :value="null">Sélectionnez</option>
                  <option :value="false">Le propriétaire directement</option>
                  <option :value="true">Une agence immobilière</option>
                </select>
              </div>
              <div class="champs-proprio" v-if="souscription.agence === false">
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Nom &amp; prénom <span class="asterix">*</span></p>
                  <input v-model="proprietaire.nom" type="text" class="champs_form_v2 _35 w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom" id="Nom" required="">
                  <input v-model="proprietaire.prenom" type="text" class="champs_form_v2 _35 w-input" maxlength="256" name="Prenom" data-name="Prenom" placeholder="Prénom" id="Prenom" required=""> 
                </div>
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Mail du propriétaire <span class="asterix">*</span></p>
                  <input v-model="proprietaire.email" type="email" class="champs_form_v2 _70 w-input" maxlength="256" name="email" data-name="email" placeholder="mail@mail.com" id="Email" required=""> 
                </div>
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Téléphone propriétaire<br><i>(facultatif)</i></p>
                  <input v-model="proprietaire.telephone" type="tel" class="champs_form_v2 _70 w-input" maxlength="10" minlength="10" name="Telephone" data-name="Telephone" placeholder="0102030405" id="Telephone" > 
                </div>
              </div>
              <div class="champs-agence" v-if="souscription.agence === true">
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Nom agence <span class="asterix">*</span></p>
                  <input v-model="proprietaire.nom" type="text" :readonly="proprietaire.id != null" class="champs_form_v2 _70 w-input" maxlength="256" name="Nom" data-name="Nom" placeholder="Nom de l'agence immobilière" id="Nom-3" required=""> 
                </div>                
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Mail <span class="asterix">*</span></p>
                  <input v-model="proprietaire.email" type="email" :readonly="proprietaire.id != null" class="champs_form_v2 _70 w-input" maxlength="256" name="Email-2" data-name="Email 2" placeholder="mail@mail.com" id="Email-2" required=""> 
                </div>
                <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Téléphone <span class="asterix">*</span></p>
                  <input v-model="proprietaire.telephone" type="tel" :readonly="proprietaire.id != null" class="champs_form_v2 _70 w-input" minlength="10" maxlength="10" name="Telephone-2" data-name="Telephone 2" placeholder="0102030405" id="Telephone-2" required=""> 
                </div>
                <!-- <div class="div_champs_form_v2 info_logement">
                  <p class="p_txt_field_form_v2 light info_logement">Nom agent immobilier <br>(facultatif)</p>
                  <input v-model="proprietaire.prenom" type="text" class="champs_form_v2 _70 w-input" maxlength="256" name="Nom-2" data-name="Nom 2" placeholder="Nom" id="Nom-2"> 
                </div> -->
              </div>
              <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">CONTINUER</a> 
            </form>            
          </div>
        </div>
      </div>
   </div>
</div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/v2/ParcourLogin';
export default {
  name: "Step1",
  components: {
    ZeroDepot,
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      load: false,
      user_lock: true,
      info_lock: true
    }
  },
  watch:{
   'souscription.loyer'(){
      this.price();
    },
    'souscription.agence'(){
      this.proprietaire.nom = null;
      this.proprietaire.prenom = null;
      this.proprietaire.email = null;
      this.proprietaire.telephone = null;
    },
    'proprietaire.telephone'(){
      if(this.proprietaire.telephone === null){
        return false;
      }
      this.proprietaire.telephone = this.proprietaire.telephone.replaceAll(" ","");
    }
  },
  methods:{
    checkForm(){
      var res = true;
      if(this.$refs.form_parcour.reportValidity() === false){
        res = false;
      }
      return res;
    },
    getAddressData(q) {
      var d = document.getElementById("map");
      var adresse = '';
      adresse += q.street_number != undefined ? q.street_number : '';
      adresse += q.route != undefined ? ' ' + q.route : '';
      if (adresse != '') {
        this.souscription.logement.adresse = adresse;
        d.value = adresse;
      }
      if (q.locality != undefined) {
        this.souscription.logement.ville = q.locality;
      }
      if (q.postal_code != undefined) {
        this.souscription.logement.cp = q.postal_code;
      }
      this.souscription.logement.lon = q.longitude;
      this.souscription.logement.lat = q.latitude;
   },  
   next(){            
      if(this.checkForm() === false || this.load){
         return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription,
        proprietaire: this.proprietaire
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
          this.$parent.next();
        }
      });  
    },    
  },
  computed: {
   min_date() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      return `${year}-${month}-${day}`
   }
  },
  mounted() {
    this.ready = true;    
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
</style>

<style>
#Type-Location{
  appearance: none;
}
.txt_modifier.lock {
   width: 100%;
   background-color: #fff;
   background-image: url(/img/lock.847d4763.svg);
   /* background-position: 100% 50%; */
   background-size: 11px;
   background-repeat: no-repeat;
}
.champs_recap_infos {
   background-image: none !important;
}
</style>
