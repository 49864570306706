<template>
<div>
   <ZeroDepot :souscription="souscription" :user="user"/>   
   <div class="section_form_v2 wf-section">
    <div class="container-content-form-suite w-container">
      <div class="w-layout-grid grid-form">
        <div id="w-node-_2e625acf-c91e-15de-ced0-db66e76400c2-b1f17bb3" class="div_precedent_v2"> <a href="javascript:void(0)" @click="$parent.previous()" class="arrow_back_v2 w-inline-block"></a> </div>
        <div id="w-node-bf925b8c-67d0-ebe0-aa05-78df02aa22be-8def2bcf" class="div-content-form">
          <div class="div-coup-dur-titre">
            <p class="p_txt_field_form_v2">Souhaitez-vous ajouter l&#x27;option &quot;Coup dur&quot; ?<span class="text-span-9"></span></p>
            <p class="coup-dur-prix">+{{getCoupDur}}€/mois</p>
          </div>
          <div class="form-step-5-souscription w-form">
            <form class="form-2">
              <div class="div-champs-form">
                <p class="p_txt_field_form_v2 small violet regular _750px">Pour bénéficier de la garantie &quot;Coup dur&quot;, vous devez être salarié et disposer d&#x27;un CDI de plus de 6 mois, <span class="text-span-16">où</span> être indépendant et exercer votre activité depuis plus d&#x27;1 an.</p>
              </div>
              <div class="checkbox-oui-non">
                <label class="w-checkbox checkbox-field" @click="souscription.coup_dur = true">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-oui" :class="souscription.coup_dur === true ? 'w--redirected-checked' : ''"></div>
                  <input type="checkbox" name="" data-name="" required="" id="checkbox" style="opacity:0;position:absolute;z-index:-1"/><span class="w-form-label"></span>
                </label>
                <label class="w-checkbox checkbox-field-2" @click="souscription.coup_dur = false">
                  <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-non" :class="souscription.coup_dur === false ? 'w--redirected-checked' : ''"></div>
                  <input type="checkbox" name="" data-name="" required="" id="checkbox" style="opacity:0;position:absolute;z-index:-1"/><span class="w-form-label"></span>
                </label>
              </div>
              <div class="div-zone-info coup-dur">
                <p class="text rose"><span class="text-span-11">Qu&#x27;est-ce que la garantie &quot;Coup dur&quot; ?</span></p>
                <p class="paragraph-14">La garantie &quot;Coup dur&quot; prend en charge <span class="text-span-15">1 mois de loyer</span> dans les cas suivants :</p>
                <ul role="list">
                  <li class="liste-point">Perte d&#x27;emploi ou cessation d&#x27;activité.</li>
                  <li class="liste-point">Incapacité totale de travail (ITT).</li>
                  <li class="liste-point">Perte totale et irréversible d&#x27;autonomie (PTIA).</li>
                  <li class="liste-point">Décès accidentel de l&#x27;assuré.</li>
                </ul>
              </div>
              <a data-w-id="0c94b6d0-e8ac-8c0b-1768-83f866cf670b" class="btn_continuer_v2 w-button" @click="next()">CONTINUER</a> 
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import ZeroDepot from '@/components/Menu/v2/ParcourLogin';
export default {
  name: "Step1",
  components: {
    ZeroDepot,
  },
  props: ['souscription', 'user', 'proprietaire'],
  data(){
    return {
      ready:false,
      load: false,      
    }
  },
  watch:{       
  },
  methods:{
    checkForm(){
      if(this.souscription.coup_dur === null){
        return false;
      }else{
        return true;
      }
    },    
    next(){            
      console.log(this.checkForm());
      if(this.checkForm() === false || this.load){
         return false;
      }
      this.load = true;
      var params = {
        user: this.user,
        souscription: this.souscription,
        proprietaire: this.proprietaire
      };
      this.$store.api.ajax('/zero-depot/v2/create', params, (res) => {
        if(res.status === true){
          this.$parent.souscription = res.data;
          this.$parent.next();
        }
      });  
    },    
  },
  computed: {   
    getCoupDur(){
      var tmp = this.souscription.loyer;
      if(this.souscription.meublee === true){
        tmp = tmp*2;
      }
      tmp = (tmp*5.67)/100
      tmp = tmp/12;
      tmp = tmp*1.15;
      return Math.ceil(tmp);
    }
  },
  mounted() {
    this.ready = true;    
  }
};
</script>
<style scoped>
.txt_sauvegarder.d-block {
  display: block;
}
</style>

<style>
#Type-Location{
  appearance: none;
}
.txt_modifier.lock {
   width: 100%;
   background-color: #fff;
   background-image: url(/img/lock.847d4763.svg);
   /* background-position: 100% 50%; */
   background-size: 11px;
   background-repeat: no-repeat;
}
.champs_recap_infos {
   background-image: none !important;
}
</style>
